var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "base-info-edit-dialog",
      attrs: {
        title: "基本信息编辑",
        visible: _vm.dialogVisible,
        width: "820px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        closed: _vm.handleCancel,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "baseInfoForm",
              staticClass: "base-info-form",
              attrs: {
                model: _vm.baseInfoForm,
                rules: _vm.baseInfoFormRules,
                "label-width": "82px",
                "label-position": "right",
              },
            },
            [
              _c("div", { staticClass: "row-title" }, [
                _c("span"),
                _c("i", [_vm._v("基本信息")]),
              ]),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "realName",
                            label: "姓名",
                            required: "",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "input-item",
                            model: {
                              value: _vm.baseInfoForm.realName,
                              callback: function ($$v) {
                                _vm.$set(_vm.baseInfoForm, "realName", $$v)
                              },
                              expression: "baseInfoForm.realName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 0 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "gender-form-item",
                                  attrs: { prop: "gender", label: "性别" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        "popper-class": "select-list-dropdown",
                                      },
                                      model: {
                                        value: _vm.baseInfoForm.gender,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.baseInfoForm,
                                            "gender",
                                            $$v
                                          )
                                        },
                                        expression: "baseInfoForm.gender",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: "未知",
                                          value: 0,
                                          disabled: "",
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "男", value: 1 },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "女", value: 2 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "select-form-item",
                                  attrs: {
                                    prop: "city",
                                    label: "城市",
                                    required: !_vm.isPersonalResume,
                                  },
                                },
                                [
                                  _c("el-cascader", {
                                    attrs: {
                                      props: { expandTrigger: "hover" },
                                      options: _vm.cities,
                                      "show-all-levels": false,
                                      clearable: true,
                                    },
                                    on: { change: _vm.selectCity },
                                    model: {
                                      value: _vm.select.selectedCity,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.select,
                                          "selectedCity",
                                          $$v
                                        )
                                      },
                                      expression: "select.selectedCity",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _vm.mobileStatus == 3 || _vm.mobileStatus == 4
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                id: "input-mobile",
                                prop: "mobile",
                                label: "手机号码",
                                required: "",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-item",
                                attrs: { placeholder: "国际手机以+或者00开头" },
                                nativeOn: {
                                  input: function ($event) {
                                    return _vm.regexMobile.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.baseInfoForm.mobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.baseInfoForm, "mobile", $$v)
                                  },
                                  expression: "baseInfoForm.mobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                id: "input-mobile",
                                prop: "mobile",
                                label: "手机号码",
                                required: "",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-item",
                                attrs: { disabled: "", value: "***********" },
                              }),
                              _vm.mobileStatus == 1
                                ? _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.viewMobileLoading,
                                          expression: "viewMobileLoading",
                                        },
                                      ],
                                      staticClass: "view-mobile-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.applyView(1)
                                        },
                                      },
                                    },
                                    [_vm._v("查看手机")]
                                  )
                                : _vm._e(),
                              _vm.mobileStatus == 2
                                ? _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        placement: "bottom",
                                        effect: "light",
                                        "popper-class": "concat-tooltip",
                                        "visible-arrow": false,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v(
                                            "\n                              候选人正在入职保证期中，暂不能查看其联"
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "系方式\n                          "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "view-mobile-btn lock" },
                                        [
                                          _c("i", {
                                            staticClass: "icon-font icon-suo",
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 0 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "yexp-form-item",
                                  attrs: {
                                    prop: "yearOfExperience",
                                    label: "工作经验",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "text-slot" }, [
                                    _vm._v("年"),
                                  ]),
                                  _c("el-input", {
                                    model: {
                                      value: _vm.baseInfoForm.yearOfExperience,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.baseInfoForm,
                                          "yearOfExperience",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "baseInfoForm.yearOfExperience",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "yexp-form-item",
                                  attrs: { prop: "age", label: "年龄" },
                                },
                                [
                                  _c("span", { staticClass: "text-slot" }, [
                                    _vm._v("岁"),
                                  ]),
                                  _c("el-input", {
                                    model: {
                                      value: _vm.baseInfoForm.age,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.baseInfoForm, "age", $$v)
                                      },
                                      expression: "baseInfoForm.age",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _vm.emailStatus == 3 || _vm.emailStatus == 4
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                id: "input-email",
                                prop: "email",
                                label: "邮箱",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-item",
                                model: {
                                  value: _vm.baseInfoForm.email,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.baseInfoForm,
                                      "email",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "baseInfoForm.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                id: "input-email",
                                prop: "email",
                                label: "邮箱",
                                required: _vm.isRecommend,
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-item",
                                attrs: { disabled: "", value: "******" },
                              }),
                              _vm.emailStatus == 1
                                ? _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.viewEmailLoading,
                                          expression: "viewEmailLoading",
                                        },
                                      ],
                                      staticClass: "view-mobile-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.applyView(2)
                                        },
                                      },
                                    },
                                    [_vm._v("查看邮箱")]
                                  )
                                : _vm._e(),
                              _vm.emailStatus == 2
                                ? _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        placement: "bottom",
                                        effect: "light",
                                        "popper-class": "concat-tooltip",
                                        "visible-arrow": false,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v(
                                            "\n                              候选人正在入职保证期中，暂不能查看其联"
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "系方式\n                          "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "view-mobile-btn lock" },
                                        [
                                          _c("i", {
                                            staticClass: "icon-font icon-suo",
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _vm.wechatStatus == 3 || _vm.wechatStatus == 4
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                id: "input-wechat",
                                prop: "wechat",
                                label: "微信",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-item",
                                attrs: { placeholder: "微信长度为6~20位" },
                                model: {
                                  value: _vm.baseInfoForm.wechat,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.baseInfoForm,
                                      "wechat",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "baseInfoForm.wechat",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                id: "input-wechat",
                                prop: "wechat",
                                label: "微信",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-item",
                                attrs: { disabled: "", value: "******" },
                              }),
                              _vm.wechatStatus == 1
                                ? _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.viewWechatLoading,
                                          expression: "viewWechatLoading",
                                        },
                                      ],
                                      staticClass: "view-mobile-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.applyView(3)
                                        },
                                      },
                                    },
                                    [_vm._v("查看微信")]
                                  )
                                : _vm._e(),
                              _vm.wechatStatus == 2
                                ? _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        placement: "bottom",
                                        effect: "light",
                                        "popper-class": "concat-tooltip",
                                        "visible-arrow": false,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v(
                                            "\n                              候选人正在入职保证期中，暂不能查看其联"
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "系方式\n                          "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "view-mobile-btn lock" },
                                        [
                                          _c("i", {
                                            staticClass: "icon-font icon-suo",
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row-title work-row-title" },
                [
                  _c("span"),
                  _c("i", [_vm._v("工作经历")]),
                  _vm.hasWorkModified
                    ? _c(
                        "el-checkbox",
                        {
                          staticClass: "checkbox-item",
                          model: {
                            value: _vm.baseInfoForm.isAddWork,
                            callback: function ($$v) {
                              _vm.$set(_vm.baseInfoForm, "isAddWork", $$v)
                            },
                            expression: "baseInfoForm.isAddWork",
                          },
                        },
                        [
                          _vm._v("添加为最新的工作经历"),
                          _c("i", { staticClass: "add-work-tip" }, [
                            _vm._v("(新增一段最近工作经历时，请记得勾选)"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "company",
                            label: "公司名称",
                            required: !_vm.isPersonalResume,
                          },
                        },
                        [
                          _vm.baseInfoForm.isFirmResume != undefined &&
                          !_vm.baseInfoForm.isFirmResume
                            ? _c("el-input", {
                                staticClass: "input-item",
                                on: { input: _vm.handleHasWorkModified },
                                model: {
                                  value: _vm.baseInfoForm.company,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.baseInfoForm, "company", $$v)
                                  },
                                  expression: "baseInfoForm.company",
                                },
                              })
                            : _c("customer-select-or-write", {
                                staticClass: "input-item",
                                attrs: {
                                  customerId: _vm.baseInfoForm.customerId,
                                  customerName: _vm.baseInfoForm.company,
                                },
                                on: {
                                  "update:customerId": function ($event) {
                                    return _vm.$set(
                                      _vm.baseInfoForm,
                                      "customerId",
                                      $event
                                    )
                                  },
                                  "update:customer-id": function ($event) {
                                    return _vm.$set(
                                      _vm.baseInfoForm,
                                      "customerId",
                                      $event
                                    )
                                  },
                                  "update:customerName": function ($event) {
                                    return _vm.$set(
                                      _vm.baseInfoForm,
                                      "company",
                                      $event
                                    )
                                  },
                                  "update:customer-name": function ($event) {
                                    return _vm.$set(
                                      _vm.baseInfoForm,
                                      "company",
                                      $event
                                    )
                                  },
                                  "finish-callback": _vm.finishCallback,
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "title",
                            label: "职位",
                            required: !_vm.isPersonalResume,
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "input-item",
                            on: { input: _vm.handleHasWorkModified },
                            model: {
                              value: _vm.baseInfoForm.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.baseInfoForm, "title", $$v)
                              },
                              expression: "baseInfoForm.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _vm.baseInfoForm.customerId &&
                  (_vm.baseInfoForm.isFirmResume ||
                    _vm.baseInfoForm.fromRecommendDetail)
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "customerDepartmentId",
                                label: "部门",
                              },
                            },
                            [
                              _c("customer-department-select", {
                                ref: "departmentSelect",
                                attrs: {
                                  customerId: _vm.baseInfoForm.customerId,
                                  departmentId:
                                    _vm.baseInfoForm.customerDepartmentId,
                                  customerDepartmentName:
                                    _vm.baseInfoForm.customerDepartmentName,
                                  placeholder:
                                    '输入完整路径，多级部门用"，"分隔',
                                },
                                on: {
                                  "update-department": _vm.updateDepartment,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { staticClass: "start-date-wrap", attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "时间",
                            prop: "workDateRange",
                            required: !_vm.isPersonalResume,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "date-picker-pannel" },
                            [
                              _c("font-icon", {
                                attrs: { href: "#icon-calendar" },
                              }),
                              _c(
                                "div",
                                { staticClass: "date-picker-wrap" },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "month",
                                      placeholder: "开始时间",
                                      "picker-options":
                                        _vm.setExperienceStartDateOption(),
                                    },
                                    on: {
                                      change: (picker) =>
                                        _vm.dateBlur(picker, "start", "work"),
                                      blur: (picker) =>
                                        _vm.dateBlur(picker, "start", "work"),
                                    },
                                    model: {
                                      value: _vm.baseInfoForm.workStartDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.baseInfoForm,
                                          "workStartDate",
                                          $$v
                                        )
                                      },
                                      expression: "baseInfoForm.workStartDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "date-picker-split" }, [
                                _vm._v("~"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "date-picker-wrap" },
                                [
                                  _vm.baseInfoForm.workEndDate &&
                                  _vm.baseInfoForm.workEndDate
                                    .toString()
                                    .indexOf("9999") > -1
                                    ? _c("div", { staticClass: "date-tonow" }, [
                                        _vm._v(
                                          "\n                                  至今\n                              "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.baseInfoForm.workEndDate &&
                                  _vm.baseInfoForm.workEndDate
                                    .toString()
                                    .indexOf("9999") > -1
                                    ? _c("el-date-picker", {
                                        staticClass: "opacity-date-picker",
                                        attrs: {
                                          type: "month",
                                          placeholder: "结束时间",
                                          "picker-options":
                                            _vm.setExperienceEndDateOption(),
                                        },
                                        model: {
                                          value: _vm.baseInfoForm.workEndDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.baseInfoForm,
                                              "workEndDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "baseInfoForm.workEndDate",
                                        },
                                      })
                                    : _vm._e(),
                                  !_vm.baseInfoForm.workEndDate ||
                                  _vm.baseInfoForm.workEndDate
                                    .toString()
                                    .indexOf("9999") === -1
                                    ? _c("el-date-picker", {
                                        attrs: {
                                          type: "month",
                                          placeholder: "结束时间",
                                          "picker-options":
                                            _vm.setExperienceEndDateOption(),
                                        },
                                        on: {
                                          change: (picker) =>
                                            _vm.dateBlur(picker, "end", "work"),
                                          blur: (picker) =>
                                            _vm.dateBlur(picker, "end", "work"),
                                        },
                                        model: {
                                          value: _vm.baseInfoForm.workEndDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.baseInfoForm,
                                              "workEndDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "baseInfoForm.workEndDate",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row-title work-row-title" },
                [
                  _c("span"),
                  _c("i", [_vm._v("教育经历")]),
                  _vm.hasEducationModified
                    ? _c(
                        "el-checkbox",
                        {
                          staticClass: "checkbox-item",
                          model: {
                            value: _vm.baseInfoForm.isAddEducation,
                            callback: function ($$v) {
                              _vm.$set(_vm.baseInfoForm, "isAddEducation", $$v)
                            },
                            expression: "baseInfoForm.isAddEducation",
                          },
                        },
                        [
                          _vm._v("添加为最新的教育经历"),
                          _c("i", { staticClass: "add-work-tip" }, [
                            _vm._v("(新增一段教育经历时，请记得勾选)"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "university-chose",
                          attrs: {
                            prop: "school",
                            label: "毕业院校",
                            required: !_vm.isPersonalResume,
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                "popper-class": "select-list-dropdown",
                                filterable: "",
                                "allow-create": "",
                              },
                              on: {
                                change: (select) =>
                                  _vm.selectChange(select, "school"),
                              },
                              model: {
                                value: _vm.baseInfoForm.school,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseInfoForm, "school", $$v)
                                },
                                expression: "baseInfoForm.school",
                              },
                            },
                            _vm._l(_vm.universityList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.name },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "degree-chose",
                          attrs: {
                            prop: "degree",
                            label: "学历",
                            required: !_vm.isPersonalResume,
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                "popper-class": "select-list-dropdown",
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.baseInfoForm.degree,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseInfoForm, "degree", $$v)
                                },
                                expression: "baseInfoForm.degree",
                              },
                            },
                            _vm._l(_vm.degreeArr, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  label: item.label,
                                  value: item.value,
                                  disabled: item.label == "未知",
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "major-chose",
                          attrs: {
                            prop: "major",
                            label: "专业",
                            required: !_vm.isPersonalResume,
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                "popper-class": "select-list-dropdown",
                                filterable: "",
                                "allow-create": "",
                              },
                              on: {
                                change: (select) =>
                                  _vm.selectChange(select, "major"),
                              },
                              model: {
                                value: _vm.baseInfoForm.major,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseInfoForm, "major", $$v)
                                },
                                expression: "baseInfoForm.major",
                              },
                            },
                            _vm._l(_vm.majorList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.name },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "start-date-wrap", attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "时间",
                            prop: "educationDateRange",
                            required: !_vm.isPersonalResume,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "date-picker-pannel" },
                            [
                              _c("font-icon", {
                                attrs: { href: "#icon-calendar" },
                              }),
                              _c(
                                "div",
                                { staticClass: "date-picker-wrap" },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "month",
                                      placeholder: "开始时间",
                                      "picker-options":
                                        _vm.setEducationStartDateOption(),
                                    },
                                    on: {
                                      change: (picker) =>
                                        _vm.dateBlur(
                                          picker,
                                          "start",
                                          "education"
                                        ),
                                      blur: (picker) =>
                                        _vm.dateBlur(
                                          picker,
                                          "start",
                                          "education"
                                        ),
                                    },
                                    model: {
                                      value:
                                        _vm.baseInfoForm.educationStartDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.baseInfoForm,
                                          "educationStartDate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "baseInfoForm.educationStartDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "date-picker-split" }, [
                                _vm._v("~"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "date-picker-wrap" },
                                [
                                  _vm.baseInfoForm.educationEndDate &&
                                  _vm.baseInfoForm.educationEndDate
                                    .toString()
                                    .indexOf("9999") > -1
                                    ? _c("div", { staticClass: "date-tonow" }, [
                                        _vm._v(
                                          "\n                                  至今\n                              "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.baseInfoForm.educationEndDate &&
                                  _vm.baseInfoForm.educationEndDate
                                    .toString()
                                    .indexOf("9999") > -1
                                    ? _c("el-date-picker", {
                                        staticClass: "opacity-date-picker",
                                        attrs: {
                                          type: "month",
                                          placeholder: "结束时间",
                                          "picker-options":
                                            _vm.setEducationEndDateOption(),
                                        },
                                        model: {
                                          value:
                                            _vm.baseInfoForm.educationEndDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.baseInfoForm,
                                              "educationEndDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "baseInfoForm.educationEndDate",
                                        },
                                      })
                                    : _vm._e(),
                                  !_vm.baseInfoForm.educationEndDate ||
                                  _vm.baseInfoForm.educationEndDate
                                    .toString()
                                    .indexOf("9999") === -1
                                    ? _c("el-date-picker", {
                                        attrs: {
                                          type: "month",
                                          placeholder: "结束时间",
                                          "picker-options":
                                            _vm.setEducationEndDateOption(),
                                        },
                                        on: {
                                          change: (picker) =>
                                            _vm.dateBlur(
                                              picker,
                                              "end",
                                              "education"
                                            ),
                                          blur: (picker) =>
                                            _vm.dateBlur(
                                              picker,
                                              "end",
                                              "education"
                                            ),
                                        },
                                        model: {
                                          value:
                                            _vm.baseInfoForm.educationEndDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.baseInfoForm,
                                              "educationEndDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "baseInfoForm.educationEndDate",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmHandle },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "score-feedback-dialog",
          attrs: {
            title:
              _vm.scoreFeedbackType == 1 ? "请输入真实有效的电话号码" : "提示",
            visible: _vm.scoreFeedbackDialogVisible,
            width: "480px",
            "show-close": true,
            "close-on-click-modal": false,
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.scoreFeedbackDialogVisible = $event
            },
          },
        },
        [
          _c("div", [
            _vm.scoreFeedbackType == 1
              ? _c("span", { staticClass: "tip" }, [
                  _vm._v("请输入真实有效的电话号码，剩余"),
                  _c("i", [_vm._v(_vm._s(_vm.scoreFeedbackLimit))]),
                  _vm._v("次填写机会。"),
                ])
              : _vm._e(),
            _vm.scoreFeedbackType == 2
              ? _c("span", { staticClass: "tip" }, [
                  _vm._v("电话号码输入错误已超过"),
                  _c("i", [_vm._v("5")]),
                  _vm._v("次，今日无法再对本简历进行更新。"),
                ])
              : _vm._e(),
            _vm.scoreFeedbackType == 3
              ? _c("span", { staticClass: "tip" }, [
                  _vm._v("今日无法再对本简历进行更新。"),
                ])
              : _vm._e(),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "score-feedback-btn",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.scoreFeedbackDialogVisible = false
                    },
                  },
                },
                [_vm._v("知道了")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }