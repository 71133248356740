import { resumeUpload as resumeUploadUrl } from '@src/js/config/api.json';
import { resumeVerify as javaResumeVerifyUrl } from '@src/js/config/javaApi.json';

export default {
    validMobile(params){
        return _request({
            method: "POST",
            url: resumeUploadUrl.valid_mobile,
            data: params
        });
    },

    // validMobileScoreCreate(params){
    //     return _request({
    //         method: "GET",
    //         baseURL: 'LbdJavaApi',
    //         javaProject: 'javaCommon',
    //         url: javaResumeVerifyUrl.mobile_verify,
    //         data: params,
    //         throwBusinessError: true
    //     });
    // },

    validMobileScore(params){
        return _request({
            method: "GET",
            url: resumeUploadUrl.verify_mobile,
            data: params,
            throwBusinessError: true
        });
    },

    validRealNameScore(params){
        return _request({
            method: "GET",
            url: resumeUploadUrl.verify_real_name,
            data: params,
            throwBusinessError: true
        });
    },

    validEmail(params){
        return _request({
            method: "POST",
            url: resumeUploadUrl.valid_email,
            data: params
        });
    },
    createCandidate(params){
        return _request({
            method: "POST",
            url: resumeUploadUrl.create_candidate,
            data: params,
            throwBusinessError: true
        });
    },
    parseResume(params){
        return _request({
            method: "POST",
            url: resumeUploadUrl.parse_resume,
            data: params,
            throwBusinessError: true
        });
    },
    resumeValidateRepeat(params){
        return _request({
            method: "POST",
            url: resumeUploadUrl.validate_resume_repeat,
            data: params,
            throwBusinessError: true
        });
    },
    getUniversityList(params){
        return _request({
            method: "POST",
            url: resumeUploadUrl.universitys,
            data: params
        });
    },
    getMajorList(params){
        return _request({
            method: "POST",
            url: resumeUploadUrl.majors,
            data: params
        });
    }
}
